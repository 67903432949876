import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import resident from './slices/resident';
import project from './slices/project';
import vote from './slices/vote';
import property from './slices/property';
import user from './slices/user';
import issue from './slices/issue';
import issueType from './slices/issueType';
import post from './slices/post';
import globalNotifications from './slices/globalNotifications';
import noResident from './slices/noResident';
import notification from './slices/notification';
import chat from './slices/chat';
import payment from './slices/payment';
import callRequest from './slices/callRequest';
import saleRealEstate from './slices/saleRealEstate';
import exportFiles from './slices/exportFile';
import partners from './slices/partners';
import partnerUsers from './slices/partnerUsers';
import partnerDeals from './slices/partnerDeals';
import partnerDiscounts from './slices/partnerDiscounts';
import partnerOffers from './slices/partnerOffers';
import partnerCategories from './slices/partnerCategories';
import partnerServices from './slices/partnerServices';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const saleRealEstatetPersistConfig = {
  key: 'saleRealEstate',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['secondaryImages'],
};

const rootReducer = combineReducers({
  resident,
  project,
  vote,
  property,
  user,
  issue,
  issueType,
  post,
  noResident,
  globalNotifications,
  notification,
  chat,
  payment,
  callRequest,
  saleRealEstate: persistReducer(saleRealEstatetPersistConfig, saleRealEstate),
  exportFiles,
  partners,
  partnerUsers,
  partnerDeals,
  partnerDiscounts,
  partnerOffers,
  partnerCategories,
  partnerServices,
});

export { rootPersistConfig, rootReducer };
