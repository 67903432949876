import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

import { fData } from 'utils/formatNumber';
import { ErrorTypeValidationText } from 'utils/validations';

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array,
  errorMessage: PropTypes.string,
};

export default function RejectionFiles({ fileRejections, errorMessage }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2">
              {path} - {fData(size)}
            </Typography>
            {errors.map(
              (error) =>
                (errorMessage || error.message) && (
                  <Typography key={error.code} variant="caption" component="p">
                    {errorMessage || ErrorTypeValidationText(error.code)}
                  </Typography>
                )
            )}
          </Box>
        );
      })}
    </Paper>
  );
}
