import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class PartnerOffers extends ApiService {
  getPartnerOffers(params) {
    return this.get(API_ENDPOINTS.partnerOffer.root, params);
  }

  getPartnerOffer(id) {
    return this.get(`${API_ENDPOINTS.partnerOffer.root}${id}/`);
  }

  addPartnerOffer(data) {
    return this.post(API_ENDPOINTS.partnerOffer.root, data);
  }

  editPartnerOffer(data, id) {
    return this.patch(`${API_ENDPOINTS.partnerOffer.root}${id}/`, data);
  }

  deletePartnerOffer(id) {
    return this.delete(`${API_ENDPOINTS.partnerOffer.root}${id}/`);
  }

  getPartnerOfferAttachments(params) {
    return this.get(API_ENDPOINTS.partnerOffer.partnerOfferAttachment.root, params);
  }

  addPartnerOfferAttachments(data) {
    return this.post(API_ENDPOINTS.partnerOffer.partnerOfferAttachment.root, data);
  }

  deletePartnerOfferAttachment(id) {
    return this.delete(`${API_ENDPOINTS.partnerOffer.partnerOfferAttachment.root}${id}/`);
  }
}

export default new PartnerOffers();
