import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Partners extends ApiService {
  getPartners(params) {
    return this.get(API_ENDPOINTS.partners.root, params);
  }

  getPartnersList() {
    return this.get(`${API_ENDPOINTS.partners.root}list/`);
  }

  getPartner(id) {
    return this.get(`${API_ENDPOINTS.partners.root}${id}/`);
  }

  getPartnerData() {
    return this.get(`${API_ENDPOINTS.partners.root}data/`);
  }

  addPartner(data) {
    return this.post(API_ENDPOINTS.partners.root, data);
  }

  editPartner(data, id) {
    return this.patch(`${API_ENDPOINTS.partners.root}${id}/`, data);
  }

  deletePartner(id) {
    return this.delete(`${API_ENDPOINTS.partners.root}${id}/`);
  }
}

export default new Partners();
