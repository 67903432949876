import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class PartnerCategories extends ApiService {
  getPartnerCategories(params) {
    return this.get(API_ENDPOINTS.partnerCategory.root, params);
  }

  getPartnerCategory(id) {
    return this.get(`${API_ENDPOINTS.partnerCategory.root}${id}/`);
  }

  addPartnerCategory(data) {
    return this.post(API_ENDPOINTS.partnerCategory.root, data);
  }

  editPartnerCategory(data, id) {
    return this.patch(`${API_ENDPOINTS.partnerCategory.root}${id}/`, data);
  }

  deletePartnerCategory(id) {
    return this.delete(`${API_ENDPOINTS.partnerCategory.root}${id}/`);
  }
}

export default new PartnerCategories();
