import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  partnerServices: null,
  partnerService: null,
  error: null,
  attachmentFiles: null,
};

const slice = createSlice({
  name: 'partnerServices',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.partnerServices = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.partnerService = action.payload;
    },
    getEntityAttachmentsSuccess(state, action) {
      state.isLoading = false;
      state.attachmentFiles = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = false;
    },
    resetEntity(state) {
      state.partnerService = null;
      state.attachmentFiles = null;
      state.error = null;
    },
    addAttachments(state, action) {
      state.attachmentFiles = [...(state.attachmentFiles || []), ...action.payload];
    },
    deleteAttachments(state, action) {
      state.attachmentFiles = action.payload;
    },
  },
});

export default slice.reducer;

export const { addAttachments, deleteAttachments } = slice.actions;

export function getPartnerServices(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = 'order',
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await ArcadaApiService.partnerServices.getPartnerServices({
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getEntitiesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPartnerService(id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const { data } = await ArcadaApiService.partnerServices.getPartnerService(id);
      dispatch(slice.actions.getEntitySuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addPartnerService(data, attachments) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const formData = getFormData(data);

      const { data: result } = await ArcadaApiService.partnerServices.addPartnerService(formData);

      if (attachments?.length) {
        attachments.forEach((file) => {
          dispatch(
            addPartnerServiceAttachments(
              getFormData({
                file,
                service_vendor: result?.id,
              })
            )
          );
        });
      }

      dispatch(slice.actions.hasSuccess());
      dispatch(slice.actions.getEntitySuccess(data));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Ծառայությունն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editPartnerService(data, dirtyData, id, attachments) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-unused-vars
      const { attachmentDeleteIds, attachmentFiles, ...rest } = dirtyData || {};

      if (Object.keys(rest).length) {
        const formData = getFormData({ ...rest });
        await ArcadaApiService.partnerServices.editPartnerService(formData, id);
      }

      if (attachmentDeleteIds?.length)
        attachmentDeleteIds?.filter((id) => ArcadaApiService.partnerServices.deletePartnerServiceAttachment(id));

      if (attachments?.length) {
        attachments.forEach((file) => {
          if (typeof file !== 'object' || file?.id) return;
          dispatch(
            addPartnerServiceAttachments(
              getFormData({
                file,
                service_vendor: id,
              })
            )
          );
        });
      }

      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Ծառայությունը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function togglePartnerServiceStatus(
  id,
  isActive,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-name',
  search,
  filters
) {
  const formData = getFormData({ is_active: isActive });

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.partnerServices.editPartnerService(formData, id);
      dispatch(getPartnerServices(skip, take, ordering, search, filters));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPartnerServiceAttachments(id) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const { data } = await ArcadaApiService.partnerServices.getPartnerServiceAttachments({ service_vendor: id });
      dispatch(slice.actions.getEntityAttachmentsSuccess(data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addPartnerServiceAttachments(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.partnerServices.addPartnerServiceAttachments(data);
      dispatch(slice.actions.hasSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function resetPartnerService() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
