import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class PartnerServices extends ApiService {
  getPartnerServices(params) {
    return this.get(API_ENDPOINTS.partnerService.root, params);
  }

  getPartnerService(id) {
    return this.get(`${API_ENDPOINTS.partnerService.root}${id}/`);
  }

  addPartnerService(data) {
    return this.post(API_ENDPOINTS.partnerService.root, data);
  }

  editPartnerService(data, id) {
    return this.patch(`${API_ENDPOINTS.partnerService.root}${id}/`, data);
  }

  deletePartnerService(id) {
    return this.delete(`${API_ENDPOINTS.partnerService.root}${id}/`);
  }

  getPartnerServiceAttachments(params) {
    return this.get(API_ENDPOINTS.partnerService.partnerServiceAttachment.root, params);
  }

  addPartnerServiceAttachments(data) {
    return this.post(API_ENDPOINTS.partnerService.partnerServiceAttachment.root, data);
  }

  deletePartnerServiceAttachment(id) {
    return this.delete(`${API_ENDPOINTS.partnerService.partnerServiceAttachment.root}${id}/`);
  }
}

export default new PartnerServices();
