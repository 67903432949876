import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  partnerCategories: null,
  partnerCategory: null,
  error: null,
};

const slice = createSlice({
  name: 'partnerCategories',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.partnerCategories = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.partnerCategory = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.partnerCategory = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getPartnerCategories(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = 'order',
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.partnerCategories.getPartnerCategories({
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPartnerCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.partnerCategories.getPartnerCategory(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addPartnerCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);

    try {
      const { data } = await ArcadaApiService.partnerCategories.addPartnerCategory(formData);

      dispatch(slice.actions.getEntitySuccess(data));
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Կատեգորիան ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editPartnerCategory(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);
    try {
      const { data } = await ArcadaApiService.partnerCategories.editPartnerCategory(formData, id);
      dispatch(slice.actions.getEntitySuccess(data));
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Կատեգորիան թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function deletePartnerCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.partnerCategories.deletePartnerCategory(id);
      dispatch(slice.actions.hasSuccess());
      dispatch(getPartnerCategories());
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function togglePartnerCategoryStatus(
  id,
  isActive,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-name',
  search,
  filters
) {
  const formData = getFormData({ is_active: isActive });

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.partnerCategories.editPartnerCategory(formData, id);
      dispatch(getPartnerCategories(skip, take, ordering, search, filters));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetPartnerCategory() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
