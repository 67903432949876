import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class PartnerDiscounts extends ApiService {
  getPartnerDiscounts(params, config) {
    return this.get(API_ENDPOINTS.partnerDiscount.root, params, config);
  }

  getPartnerDiscount(id) {
    return this.get(`${API_ENDPOINTS.partnerDiscount.root}${id}/`);
  }

  getPartnerDiscountData() {
    return this.get(`${API_ENDPOINTS.partnerDiscount.root}data/`);
  }

  addPartnerDiscount(data) {
    return this.post(API_ENDPOINTS.partnerDiscount.root, data);
  }

  editPartnerDiscount(data, id) {
    return this.patch(`${API_ENDPOINTS.partnerDiscount.root}${id}/`, data);
  }

  deletePartnerDiscount(id) {
    return this.delete(`${API_ENDPOINTS.partnerDiscount.root}${id}/`);
  }
}

export default new PartnerDiscounts();
